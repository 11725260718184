// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bz_k4";
export var caseStudyBackground__lineColor = "bz_kW";
export var caseStudyCta__bgColor = "bz_l3";
export var caseStudyHead__imageWrapper = "bz_kT";
export var caseStudyProjectsSection = "bz_k5";
export var caseStudyQuote__bgDark = "bz_l4";
export var caseStudyQuote__bgLight = "bz_k2";
export var caseStudyQuote__bgRing = "bz_k1";
export var caseStudySolution__ring = "bz_kZ";
export var caseStudySolution__ringThree = "bz_l5";
export var caseStudySolution__ringTwo = "bz_k0";
export var caseStudyVideo = "bz_k7";
export var caseStudyVideo__ring = "bz_k8";
export var caseStudy__bgDark = "bz_kS";
export var caseStudy__bgLight = "bz_kR";